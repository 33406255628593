import React from "react"
import PageSmall from "./../components/page-small"

import SinglePressArticle from "./../components/single-press-article"

import notes from "./../data/press"
import "../styles/press.scss"

const IndexPage = () => {
  return (
    <PageSmall title="Press" pageTitle="Press">
      <div id="press">
        <div className="articles px-16">
          {notes.map((note, index) => (
            <SinglePressArticle note={note} key={`single-note-${index}`} />
          ))}
        </div>
      </div>
    </PageSmall>
  )
}

export default IndexPage
